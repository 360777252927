import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useGetEstimatesList } from '~/hooks/estimates';
import styles from './mobilization-form.module.scss';

import {
  ErrorField,
  InputNumber,
  StyledSelect,
} from '~/lib/HooksFormFields';
import PreviewField from '~/components/PreviewField';
import { IOption } from '~/types/options';

const MobilizationForm = ({ isPreview } : { isPreview: boolean }) => {
  const { control, watch, setValue } = useFormContext();
  const phase = watch();
  const type = watch('mobilization.type');
  const calculMode = watch('mobilization.calculMode');
  const { data: list = { mobilization: {} } } = useGetEstimatesList();
  const {
    mobilization,
    mobilizationModes,
    mobilizationRythmes = [],
    umbrellaMobilizationRythmes = [],
  } = list.mobilization;

  const rythmes = mobilizationRythmes.filter((r :any) => {
    if (r.isSuspendu && calculMode !== 'tonnage') {
      return null;
    }
    return r;
  });

  const umbrellaRythmes = umbrellaMobilizationRythmes.filter((r :any) => {
    if (r.isSuspendu && calculMode !== 'tonnage') {
      return null;
    }
    return r;
  });

  const rythmesOptions = phase.type === 'umbrella' ? umbrellaRythmes : rythmes;

  return (
    <div id="montage" className={styles.mobilization}>
      <h4>Montage/Démontage</h4>
      <div className={styles.row}>
        <div className={styles.containerField}>
        {isPreview
          ? <PreviewField
              label='Type de Montage'
              value={mobilization?.find((a:IOption) => a.value === phase?.mobilization?.type)?.label || '---'}
            />
          : <StyledSelect
            label='Type de Montage'
            name='mobilization.type'
            control={control}
            options={mobilization}
            handleChange={(opt) => {
              if (opt.value !== 'Partiel') {
                setValue('mobilization.assemblyTonnage', null);
                setValue('mobilization.disassemblyTonnage', null);
              }
            }}
          />
        }
        </div>
      </div>
      {type === 'Partiel'
        && <div className={styles.row}>
          <div className={styles.containerField}>
          {isPreview
            ? <PreviewField
              label='Tonnage à monter'
              value={phase?.mobilization?.assemblyTonnage || '---'}
            />
            : <InputNumber
              name='mobilization.assemblyTonnage'
              label='Tonnage à monter'
              control={control}
              max={phase?.dimension?.tonnage}
            />
          }
          </div>
          <div className={styles.containerField}>
          {isPreview
            ? <PreviewField
              label='Tonnage à démonter'
              value={phase?.mobilization?.disassemblyTonnage || '---'}
            />
            : <InputNumber
              name='mobilization.disassemblyTonnage'
              label='Tonnage à démonter'
              control={control}
              max={phase?.dimension?.tonnage}
            />
          }
          </div>
        </div>
      }
      {type !== 'Aucun'
        && <div className={styles.row}>
        <div className={styles.containerField}>
        {isPreview
          ? <PreviewField
              label='Mode de calul'
              value={mobilizationModes?.find((a:IOption) => a.value === phase?.mobilization?.calculMode)?.label || '---'}
            />
          : <StyledSelect
            label='Mode de calul'
            name='mobilization.calculMode'
            control={control}
            options={mobilizationModes}
            handleChange={() => {
              setValue('mobilization.rythme', null);
            }}
          />
          }
           {calculMode === 'multi' && !phase?.dimension?.scaffoldingSurface
            && <ErrorField message="La surface n'est pas renseignée" />
          }
        </div>
        <div className={styles.containerField}>
        {isPreview
          ? <PreviewField
              label='Choix cadence'
              value={rythmesOptions.find((a:IOption) => a.value === phase?.mobilization?.rythme)?.label || '---'}
            />
          : <StyledSelect
            label='Choix cadence'
            name='mobilization.rythme'
            control={control}
            options={rythmesOptions}
          />
        }
        </div>
      </div>

      }
    </div>
  );
};

export default MobilizationForm;
