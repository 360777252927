import React from 'react';
import { useFormContext } from 'react-hook-form';
import styles from './cart-form.module.scss';

import {
  InputNumber,
  StyledSelect,
} from '~/lib/HooksFormFields';
import PreviewField from '~/components/PreviewField';
import { IOption } from '~/types/options';
import { useGetEstimatesList } from '~/hooks/estimates';

const defaultAssembly = {
  countDays: null,
  dayPrice: null,
  transportPrice: null,
};

const defaultAssemblyPrice = {
  countDays: null,
  dayPrice: 210,
  transportPrice: null,
};

const CartForm = ({ isPreview } : { isPreview: boolean }) => {
  const { control, watch, setValue } = useFormContext();
  const { data: list = { cart: [] } } = useGetEstimatesList();
  const phase = watch();
  const cart = watch('cart.type');

  return (
    <div id="cart" className={styles.cart}>
      <h4>Chariot</h4>
      <div className={styles.row}>
        <div className={`${styles.containerField} ${styles.checkbox}`}>
        {isPreview
          ? <PreviewField
              label='Type de Montage'
              value={list?.carts?.find((a:IOption) => a.value === cart)?.label || '---'}
            />
          : <StyledSelect
            label='Type de Chariot'
            name='cart.type'
            control={control}
            options={list.carts}
            handleChange={(opt) => {
              if (opt.value === '') {
                setValue('cart.assembly', defaultAssembly);
                setValue('cart.disassembly', defaultAssembly);
              } else {
                setValue('cart.assembly', defaultAssemblyPrice);
                setValue('cart.disassembly', defaultAssemblyPrice);
              }
            }}
          />
        }
        </div>
      </div>
        {cart
        && <>
            <h5>Montage</h5>
            <div className={`${styles.row} ${styles.assembly}`}>
              <div className={`${styles.containerField}`}>
              {isPreview
                ? <PreviewField
                  label='Nombre de jour'
                  value={phase?.cart?.assembly?.countDays ? `${phase?.cart?.assembly?.countDays} jours` : '---'}
              />
                : <InputNumber
                  name='cart.assembly.countDays'
                  label='Nombre de jour'
                  control={control}
                />
              }
              </div>
              <div className={`${styles.containerField}`}>
              {isPreview
                ? <PreviewField
                  label='Tarif journalier'
                  value={phase?.cart?.assembly?.dayPrice ? `${phase?.cart?.assembly?.dayPrice} €` : '---'}
                />
                : <InputNumber
                  name='cart.assembly.dayPrice'
                  label='Tarif journalier'
                  control={control}
                />
              }
              </div>
              <div className={`${styles.containerField}`}>
              {isPreview
                ? <PreviewField
                  label='Frais de transport A/R'
                  value={phase?.cart?.assembly?.transportPrice ? `${phase?.cart?.assembly?.transportPrice} €` : '---'}
                />
                : <InputNumber
                  name='cart.assembly.transportPrice'
                  label='Frais de transport A/R'
                  control={control}
                />
              }
              </div>
            </div>
            <h5>Démontage</h5>
            <div className={`${styles.row} ${styles.assembly}`}>
              <div className={`${styles.containerField}`}>
                {isPreview
                  ? <PreviewField
                    label='Nombre de jour'
                    value={phase?.cart?.disassembly?.countDays ? `${phase?.cart?.disassembly?.countDays} jours` : '---'}
                />
                  : <InputNumber
                    name='cart.disassembly.countDays'
                    label='Nombre de jour'
                    control={control}
                  />
                }
              </div>
              <div className={`${styles.containerField}`}>
              {isPreview
                ? <PreviewField
                  label='Tarif journalier'
                  value={phase?.cart?.disassembly?.dayPrice ? `${phase?.cart?.disassembly?.dayPrice} €` : '---'}
                />
                : <InputNumber
                  name='cart.disassembly.dayPrice'
                  label='Tarif journalier'
                  control={control}
                />
              }
              </div>
              <div className={`${styles.containerField}`}>
              {isPreview
                ? <PreviewField
                  label='Tarif journalier'
                  value={phase?.cart?.disassembly?.transportPrice ? `${phase?.cart?.disassembly?.transportPrice} €` : '---'}
                />
                : <InputNumber
                  name='cart.disassembly.transportPrice'
                  label='Frais de transport A/R'
                  control={control}
                />
              }
              </div>
            </div>
        </>
        }
    </div>
  );
};

// assembly: {
//   countDays: { type: Number, default: null },
//   dayPrice: { type: Number, default: null },
//   transportPrice: { type: Number, default: null },
// },

export default CartForm;
