import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useGetEstimate, useGetEstimatesList } from '~/hooks/estimates';
import styles from './dimension-form.module.scss';

import {
  InputNumber,
  StyledSelect,
  Checkbox,
  ErrorField,
} from '~/lib/HooksFormFields';
import PreviewField from '~/components/PreviewField';
import { IOption } from '~/types/options';
import { IPhase } from '~/types/estimates';

const DimensionForm = ({ isPreview } : { isPreview: boolean }) => {
  const { control, setValue, watch } = useFormContext();
  const { data: list = { dimension: {} } } = useGetEstimatesList();
  const { id, phaseId } = useParams();
  const {
    data: estimate,
  } = useGetEstimate(id || '');

  const phase = useMemo(() => {
    const found = estimate?.phases?.find((d: IPhase) => d._id === phaseId);
    return found;
  }, [estimate, phaseId]);

  const {
    ratioUsages,
    accordingTo,
  } = list.dimension;

  const accordingToValue = watch('dimension.accordingTo');
  const isUmbrella = watch('type') === 'umbrella';

  return (
    <div id="dimension" className={styles.dimension}>
      <h4>Dimension</h4>
      <div className={styles.row}>
        <div className={styles.containerField}>
          {isPreview
            ? <PreviewField
              label='Longeur'
              value={phase?.dimension?.length ? `${phase?.dimension?.length} m` : '---'}
          />
            : <InputNumber
              name='dimension.length'
              label='Longeur'
              placeholder='en m'
              control={control}
            />
          }
        </div>
        <div className={styles.containerField}>
        {isPreview
          ? <PreviewField
              label='Largeur'
              value={phase?.dimension?.width ? `${phase?.dimension?.width} m` : '---'}
          />
          : <InputNumber
            name='dimension.width'
            label='Largeur'
            placeholder='en m'
            control={control}
          />
        }
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.containerField}>
        {isPreview
          ? <PreviewField
              label='Hauteur'
              value={phase?.dimension?.height ? `${phase?.dimension?.height} m` : '---'}
          />
          : <InputNumber
            name='dimension.height'
            label='Hauteur'
            placeholder='en m'
            control={control}
          />
        }
        </div>
      </div>
      {!isUmbrella
        && <>
        <div className={styles.row}>
          <div className={styles.containerField}>
            {isPreview
              ? <PreviewField
                  label='Surface échafaudages'
                  value={phase?.dimension?.scaffoldingSurface ? `${phase?.dimension?.scaffoldingSurface} m²` : '---'}
              />
              : <InputNumber
              name='dimension.scaffoldingSurface'
              label='Surface échafaudages'
              placeholder='en m'
              control={control}
            />
            }
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.containerField}>
          {isPreview
            ? <PreviewField
                    label='Selon'
                  value={accordingTo?.find((a:IOption) => a.value === phase?.dimension?.accordingTo)?.label || '---'}
              />
            : <StyledSelect
              name='dimension.accordingTo'
              label='Selon'
              control={control}
              options={accordingTo}
              handleChange={(opt) => {
                if (opt.value === 'Ratio') {
                  setValue('dimension.countingBE', null);
                  setValue('dimension.worksiteAdaptation', null);
                  setValue('dimension.counting', null);
                } else if (opt.value === 'Décompte BE') {
                  setValue('dimension.ratioUsage', null);
                  setValue('dimension.guardrailInterior', null);
                  setValue('dimension.counting', null);
                } else if (opt.value === 'Décompte manuel') {
                  setValue('dimension.ratioUsage', null);
                  setValue('dimension.guardrailInterior', null);
                  setValue('dimension.countingBE', null);
                  setValue('dimension.worksiteAdaptation', null);
                }
              }}
            />
            }
          </div>
        </div>

        {accordingToValue === 'Ratio' && (
          <div className={styles.row}>
            <div className={styles.containerField}>
            {isPreview
              ? <PreviewField
                  label='Selon'
                  value={ratioUsages?.find((a:IOption) => a.value === phase?.dimension?.ratioUsage)?.label || '---'}
              />
              : <StyledSelect
                label='Utilisation du ratio pour une structure en'
                name='dimension.ratioUsage'
                control={control}
                options={ratioUsages}
              />
            }
            {phase?.dimension?.ratioUsage === 'Tour limon 10 pieds' && phase?.dimension?.height > 22
              && <ErrorField message="La hauteur ne peut pas être supérieur à 22 m" />
            }
            </div>
            <div className={`${styles.containerField} ${styles.checkbox}`}>
              <Checkbox
                name='dimension.guardrailInterior'
                label='Ajouter gardes-corps interieurs'
                disabled={isPreview}
                control={control}
              />
            </div>
          </div>
        )}
        {accordingToValue === 'Décompte BE' && (
          <div className={styles.row}>
            <div className={styles.containerField}>
              <InputNumber
                name='dimension.countingBE'
                label='Décompte BE'
                control={control}
              />
            </div>
            <div className={styles.containerField}>
              <InputNumber
                name='dimension.worksiteAdaptation'
                label='Adaptation chantier'
                placeholder='en %'
                control={control}
              />
            </div>
          </div>
        )}
        {accordingToValue === 'Décompte manuel' && (
          <div className={styles.row}>
            <div className={styles.containerField}>
              <InputNumber
                name='dimension.counting'
                label='Décompte'
                control={control}
              />
            </div>
          </div>
        )}
        {phase?.dimension?.tonnage && phase?.dimension?.tonnage > 0
          && <div className={styles.row}>
            <p className={styles.tonnage}>Tonnage : <span>{phase?.dimension?.tonnage}</span></p>
          </div>
        }
        </>
      }
      {isUmbrella
        && <>
          <div className={styles.row}>
            <div className={styles.containerField}>
              {isPreview
                ? <PreviewField
                    label='Surface Réhausse'
                    value={phase?.dimension?.raised?.surface ? `${phase?.dimension?.raised?.surface} m²` : '---'}
                />
                : <InputNumber
                name='dimension.raised.surface'
                label='Surface Réhausse'
                placeholder='en m'
                control={control}
              />
              }
            </div>
            <div className={styles.containerField}>
              {isPreview
                ? <PreviewField
                    label='Tonnage Réhausse'
                    value={phase?.dimension?.raised?.tonnage ? `${phase?.dimension?.raised?.tonnage} T` : '---'}
                />
                : <InputNumber
                name='dimension.raised.tonnage'
                label='Tonnage Réhausse'
                control={control}
              />
              }
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.containerField}>
              {isPreview
                ? <PreviewField
                    label='Surface Parapluie'
                    value={phase?.dimension?.umbrella?.surface ? `${phase?.dimension?.umbrella?.surface} m²` : '---'}
                />
                : <InputNumber
                name='dimension.umbrella.surface'
                label='Surface Parapluie'
                placeholder='en m'
                control={control}
              />
              }
            </div>
            <div className={styles.containerField}>
              {isPreview
                ? <PreviewField
                    label='Tonnage Parapluie'
                    value={phase?.dimension?.umbrella?.tonnage ? `${phase?.dimension?.umbrella?.tonnage} T` : '---'}
                />
                : <InputNumber
                name='dimension.umbrella.tonnage'
                label='Tonnage Parapluie'
                control={control}
              />
              }
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.containerField}>
              {isPreview
                ? <PreviewField
                    label='Surface Baches verticales'
                    value={phase?.dimension?.verticalTarpaulins?.surface ? `${phase?.dimension?.verticalTarpaulins?.surface} m²` : '---'}
                />
                : <InputNumber
                name='dimension.verticalTarpaulins.surface'
                label='Surface Baches verticales'
                placeholder='en m'
                control={control}
              />
              }
            </div>
            <div className={styles.containerField}>
              {isPreview
                ? <PreviewField
                    label="Remise sur Bâchage"
                    value={list?.discount?.verticalTarpaulins((opt: IOption) => opt.value === phase?.dimension?.verticalTarpaulins?.surface)?.label || '---'}
                />
                : <StyledSelect
                name='dimension.verticalTarpaulins.discount'
                label='Remise sur Bâchage'
                control={control}
                options={list?.discount?.umbrellaTarpaulins || []}
              />
              }
            </div>
          </div>
        </>
      }
    </div>
  );
};

export default DimensionForm;
