/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import {
  INote,
  IEstimate,
  ArgsGetEstimates,
  IPhase,
  IVarious,
} from '~/types/estimates';

const getEstimates = async (params: ArgsGetEstimates) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/estimates`,
    {
      headers: {
        Authorization: token,
      },
      params,
    },
  );
  return data.estimates;
};

const getStatsEstimates = async () => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/estimates/stats`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data.stats;
};

const getEstimatesList = async () => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/estimates/list`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data?.list;
};

const getEstimate = async (id: string) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/estimates/${id}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data.estimate;
};

const sendEstimate = async (id: string) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/estimates/send/${id}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data;
};

const createEstimateVersion = async (id: string) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/estimates/create-version/${id}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data;
};

const duplicateEstimate = async ({
  estimateId,
  clientId,
}:{
  estimateId: string,
  clientId: string
}) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/estimates/duplicate/${estimateId}/${clientId}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data;
};

const updateEstimateTransport = async ({
  estimateId,
}:{
  estimateId: string,
}) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/estimates/${estimateId}/transport`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data;
};

const duplicateEstimatePhase = async ({
  estimateId,
  phaseId,
}:{
  estimateId: string,
  phaseId: string
}): Promise<{ estimate: IEstimate }> => {
  const token = localStorage.getItem('token');
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/estimates/${estimateId}/phases/duplicate/${phaseId}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data;
};

const createEstimate = async (data: Partial<IEstimate>): Promise<{ estimate: IEstimate }> => {
  const token = localStorage.getItem('token');
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/estimates`,
    data,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

const updateEstimate = async (data: Partial<IEstimate>): Promise<{ estimate: IEstimate }> => {
  const token = localStorage.getItem('token');
  const { _id, ...updatedData } = data;
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/estimates/${_id}`,
    updatedData,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

const createEstimatePhase = async (
  data: { estimateId: string, type: string },
): Promise<{ estimate: IEstimate }> => {
  const token = localStorage.getItem('token');
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/estimates/${data.estimateId}/phases`,
    {
      type: data.type,
    },
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

const updateEstimatePhase = async (data: Partial<IPhase> & { estimateId : string })
: Promise<{ estimate: IEstimate }> => {
  const token = localStorage.getItem('token');
  const { _id, estimateId, ...updatedData } = data;
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/estimates/${estimateId}/phases/${_id}`,
    updatedData,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

const deleteEstimatePhase = async ({
  estimateId,
  phaseId,
} : {
  estimateId: string,
  phaseId: string
})
: Promise<{ estimate: IEstimate }> => {
  const token = localStorage.getItem('token');
  const response = await axios.delete(
    `${process.env.REACT_APP_API_URL}/estimates/${estimateId}/phases/${phaseId}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

const updateEstimateVarious = async (data: Partial<IVarious> & { estimateId : string })
: Promise<{ estimate: IEstimate }> => {
  const token = localStorage.getItem('token');
  const { _id, estimateId, ...updatedData } = data;
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/estimates/${estimateId}/various/${_id}`,
    updatedData,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

const createEstimateVarious = async (estimateId: string): Promise<{ estimate: IEstimate }> => {
  const token = localStorage.getItem('token');
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/estimates/${estimateId}/various`,
    {},
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

const deleteEstimateVarious = async ({
  estimateId,
  variousId,
} : {
  estimateId: string,
  variousId: string
})
: Promise<{ estimate: IEstimate }> => {
  const token = localStorage.getItem('token');
  const response = await axios.delete(
    `${process.env.REACT_APP_API_URL}/estimates/${estimateId}/various/${variousId}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

const addEstimateNote = async (data: Partial<INote> & { documentId: string })
: Promise<{ estimate: IEstimate }> => {
  const token = localStorage.getItem('token');
  const { documentId, ...updatedData } = data;
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/estimates/${documentId}/notes`,
    updatedData,
    {
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  return response.data;
};

const updateEstimateNote = async (data: Partial<INote> & { documentId: string })
: Promise<{ estimate: IEstimate }> => {
  const token = localStorage.getItem('token');
  const { documentId, _id, ...updatedData } = data;
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/estimates/${documentId}/notes/${_id}`,
    updatedData,
    {
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  return response.data;
};

const deleteEstimateNote = async ({
  noteId, estimateId,
} : {
  noteId: string, estimateId: string
}) : Promise<{ estimate: IEstimate }> => {
  const token = localStorage.getItem('token');
  const response = await axios.delete(
    `${process.env.REACT_APP_API_URL}/estimates/${estimateId}/notes/${noteId}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

const deleteEstimate = async ({
  estimateId,
} : {
  estimateId: string
}) : Promise<{ estimate: IEstimate }> => {
  const token = localStorage.getItem('token');
  const response = await axios.delete(
    `${process.env.REACT_APP_API_URL}/estimates/${estimateId}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

const exportEstimate = async (id: string, filename: string | null) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/estimates/export/${id}`,
    {
      headers: {
        Authorization: token,
      },
      responseType: 'blob',
    },
  );
  const fileURL = URL.createObjectURL(data);
  if (filename) {
    const link = document.createElement('a');
    link.href = fileURL;
    link.download = filename; // Set filename for download
    link.click();
  } else {
    window.open(fileURL, '_blank');
  }
  setTimeout(() => URL.revokeObjectURL(fileURL), 100);
  return data;
};

export {
  getEstimate,
  getEstimates,
  getStatsEstimates,
  getEstimatesList,
  createEstimate,
  updateEstimate,
  deleteEstimate,
  sendEstimate,
  duplicateEstimate,
  updateEstimateTransport,
  createEstimateVersion,
  createEstimatePhase,
  updateEstimatePhase,
  deleteEstimatePhase,
  duplicateEstimatePhase,
  createEstimateVarious,
  updateEstimateVarious,
  deleteEstimateVarious,
  addEstimateNote,
  updateEstimateNote,
  deleteEstimateNote,
  exportEstimate,
};
