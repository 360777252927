import React from 'react';
import { BsCheck } from 'react-icons/bs';
import { useForm } from 'react-hook-form';
import styles from './create-client.module.scss';

import {
  ErrorField,
  InputText,
  StyledSelect,
} from '~/lib/HooksFormFields';

import { useCreateClient, useGetClientsList } from '~/hooks/clients';

const CreateClient = ({
  onSuccess,
  close,
} : {
  onSuccess: (estimateId: string) => void,
  close: () => void,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({

  });
  const { data: list = {} } = useGetClientsList();

  const {
    isLoading,
    mutateAsync: createClient,
  } = useCreateClient();

  const {
    agencies = [],
  } = list;

  const submit = async (data: any) => {
    try {
      const { client } = await createClient(data);
      onSuccess(client._id);
    } catch (e) {
      console.log(e);
    }
  };

  // name,
  // address

  return (
    <div className={styles.form}>
      <h3>Créer un client</h3>
      <div className={styles.fields}>
        <div className={styles.containerField}>
          <InputText
            name='name'
            label='Nom du client'
            control={control}
            rules={{ required: 'le nom est requis' }}
          />
          {errors?.name?.message && <ErrorField message={errors?.name?.message} />}
        </div>
        <div className={styles.containerField}>
          <InputText
            name='address'
            label='Adresse'
            control={control}
            rules={{ required: "l'adresse est requis" }}
          />
          {errors?.address?.message && <ErrorField message={errors?.address?.message} />}
        </div>
        <div className={styles.containerField}>
          <StyledSelect
            name='agency'
            label='Choisir une agence'
            control={control}
            options={agencies}
            rules={{ required: "l'agence est requise" }}
            isSearchable
          />
          {errors?.agency?.message && <ErrorField message={errors?.agency?.message} />}
        </div>
      </div>
      <div className={styles.buttons}>
        <button className='invisible' onClick={() => close()}>Annuler</button>
        <button disabled={isLoading} onClick={handleSubmit(submit)}>
          <span className='icon'><BsCheck /></span>
          <span>Valider</span>
        </button>
      </div>
    </div>
  );
};

export default CreateClient;
