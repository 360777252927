const get = (obj: Record<string, any> = {}, path: string | string[]): any => {
  const properties = Array.isArray(path) ? path : path.split('.');
  return properties.reduce((prev: any, curr: any) => prev && prev[curr], obj);
};

const downloadFile = (url: string, filename: string) => {
  fetch(url)
    .then((response) => response.blob()) // Convert response to blob
    .then((blob) => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob); // Create a temporary URL for the blob
      link.download = filename; // Set the filename for download
      link.click(); // Simulate a click to trigger download
    })
    .catch((err: any) => console.error('Error downloading file:', err));
};

function daysDifference(date: Date): number {
  const today = new Date();
  // On retire l'heure pour obtenir une différence en jours
  today.setHours(0, 0, 0, 0);
  date.setHours(0, 0, 0, 0);

  const differenceInTime = date.getTime() - today.getTime();
  const differenceInDays = differenceInTime / (1000 * 60 * 60 * 24);

  return differenceInDays;
}

function formatDuration(minutes:number) {
  const hours = Math.floor(minutes / 60); // Divise par 60 pour obtenir les heures
  const remainingMinutes = minutes % 60; // Obtenir les minutes restantes

  // Ajoute un 0 devant si les minutes sont inférieures à 10
  const formattedMinutes = remainingMinutes.toString().padStart(2, '0');

  return `${hours}h${formattedMinutes}`;
}

export {
  // eslint-disable-next-line import/prefer-default-export
  get,
  downloadFile,
  daysDifference,
  formatDuration,
};
