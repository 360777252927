/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import {
  IClient,
  ArgsGetClients,
  INote,
} from '~/types/clients';

const getClients = async (params: ArgsGetClients) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/clients`,
    {
      headers: {
        Authorization: token,
      },
      params,
    },
  );
  return data;
};

const getClientsList = async () => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/clients/list`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data?.list;
};

const getClient = async (id: string) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/clients/${id}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data.client;
};

const updateClient = async (data: Partial<IClient>): Promise<{ client: IClient }> => {
  const token = localStorage.getItem('token');
  const { _id, ...updatedData } = data;
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/clients/${_id}`,
    updatedData,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

const createClient = async (data: Partial<IClient>)
: Promise<{ client: IClient }> => {
  const token = localStorage.getItem('token');
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/clients/`,
    data,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

const addClientNote = async (data: Partial<INote> & { documentId: string })
: Promise<{ client: IClient }> => {
  const token = localStorage.getItem('token');
  const { documentId, ...updatedData } = data;
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/clients/${documentId}/notes`,
    updatedData,
    {
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  return response.data;
};

const updateClientNote = async (data: Partial<INote> & { documentId: string })
: Promise<{ client: IClient }> => {
  const token = localStorage.getItem('token');
  const { documentId, _id, ...updatedData } = data;
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/clients/${documentId}/notes/${_id}`,
    updatedData,
    {
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  return response.data;
};

const deleteClientNote = async ({
  noteId, clientId,
} : {
  noteId: string, clientId: string
}) : Promise<{ client: IClient }> => {
  console.log(deleteClientNote);
  const token = localStorage.getItem('token');
  const response = await axios.delete(
    `${process.env.REACT_APP_API_URL}/clients/${clientId}/notes/${noteId}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

export {
  getClient,
  getClients,
  getClientsList,
  updateClient,
  createClient,
  addClientNote,
  updateClientNote,
  deleteClientNote,
};
