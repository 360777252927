import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useGetEstimate, useGetEstimatesList } from '~/hooks/estimates';
import styles from './rental-form.module.scss';

import {
  StyledSelect,
} from '~/lib/HooksFormFields';
import PreviewField from '~/components/PreviewField';
import { IPhase } from '~/types/estimates';

const RentalForm = ({ isPreview } : { isPreview: boolean }) => {
  const { control } = useFormContext();
  const { id, phaseId } = useParams();
  const {
    data: estimate,
  } = useGetEstimate(id || '');

  const phase = useMemo(() => {
    const found = estimate?.phases.find((d: IPhase) => d._id === phaseId);
    return found;
  }, [estimate, phaseId]);

  const { data: list = { rental: {} } } = useGetEstimatesList();
  const {
    durations,
  } = list.rental;

  return (
    <div id="location" className={styles.rental}>
      <h4>Location</h4>
      <div className={styles.row}>
        <div className={styles.containerField}>
          {isPreview
            ? <PreviewField
              label='Durée'
              value={phase?.rental?.duration ? `${phase.rental.duration} mois` : '---'}
            />
            : <StyledSelect
            label='Durée'
            name='rental.duration'
            control={control}
            options={durations}
          />
          }
        </div>
      </div>
      {(phase?.rental && Number(phase.rental.duration) > 0)
        && <>
        {(phase?.rental?.unitPrice && phase?.rental?.unitPrice > 0)
          && <div className={styles.row}>
            <p className={`${styles.price} ${estimate?.location?.discount && estimate?.location?.discount > 0 ? styles.discount : ''}`}>
              Prix unitaire {estimate?.location.globalPrice ? `(pour ${estimate?.worksite.duration} mois)` : ''} : <span>{phase?.rental?.unitPrice} €</span>
              {' '}{estimate?.location?.discount && estimate?.location?.discount > 0 ? <span>{(phase.rental.unitPrice * (1 - estimate.location.discount)).toFixed(2)} €</span> : null}
            </p>
          </div>
        }
        {(phase?.rental?.raisedUnitPrice && phase?.rental?.raisedUnitPrice > 0)
          && <div className={styles.row}>
            <p className={`${styles.price} ${estimate?.location?.discount && estimate?.location?.discount > 0 ? styles.discount : ''}`}>
              Prix unitaire réhausse :<span>
              {phase?.rental?.raisedUnitPrice} €</span>
              {' '}{estimate?.location?.discount && estimate?.location?.discount > 0 ? <span>{(phase.rental.raisedUnitPrice * (1 - estimate.location.discount)).toFixed(2)} €</span> : null}
            </p>
          </div>
        }
        </>
        }
    </div>
  );
};

export default RentalForm;
