import React, { useEffect, useCallback, useMemo } from 'react';
import { useForm, FieldValues } from 'react-hook-form';
import { useDebounceCallback } from 'usehooks-ts';
import { useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { IoTrash } from 'react-icons/io5';
import { GrUpdate } from 'react-icons/gr';
import styles from './general-infos.module.scss';

import {
  useGetEstimate, useUpdateEstimate, useGetEstimatesList, useDeleteEstimate,
  useUpdateEstimateTransport,
} from '~/hooks/estimates';

import {
  InputText,
  InputNumber,
  StyledSelect,
  InputFormatPattern,
  InputEmail,
  ErrorField,
  Textarea,
  Checkbox,
} from '~/lib/HooksFormFields';
import { IPricing } from '~/types/agencies';
import PreviewField from '~/components/PreviewField';
import { IOption } from '~/types/options';
import withModalConfirm from '~/lib/withModalConfirm';
import { useUserProfile } from '~/hooks/auth';
import { formatDuration } from '~/utils';

const requiredRule = { required: 'Ce champ est requis' };

const GeneralInfos = ({
  isPreview,
  confirm,
}: {
  isPreview: boolean,
  confirm: (message: string, action?: () => void,
    cancel?: () => void) => void
}) => {
  const { id } = useParams();
  const {
    data: estimate = {
      agency: null,
      logistics: null,
      status: 'in-progress',
      updatedAt: null,
      hourlyRate: {
        price: null,
      },
      worksite: {
        materialUsed: 'Multidirectionnel',
        city: '',
      },
      transport: {
        details: {
          price: null,
        },
      },
    },
  } = useGetEstimate(id || '');

  const navigate = useNavigate();

  const { data: list = {} } = useGetEstimatesList();
  const { data: userAuth } = useUserProfile();

  const {
    departments, discount, worksite, agencies,
  } = list;

  const operationalBases = useMemo(() => {
    const agency = agencies?.find((a: any) => a.value === estimate?.agency?._id);
    if (agency) {
      return agency.pricing
        .sort((a:IPricing, b: IPricing) => a.location.localeCompare(b.location))
        .map((a: IPricing) => ({ label: a.location, value: a.location, ...a }));
    }
    return [];
  }, [agencies, estimate]);

  const {
    mutate: updateEstimate,
  } = useUpdateEstimate('Les modifications ont été prise en compte');

  const {
    mutate: deleteEstimate,
  } = useDeleteEstimate('Le devis a été supprimé');

  const {
    mutate: updateEstimateTransport,
    error: transportError,
  } = useUpdateEstimateTransport('Les modifications ont été prise en compte');
  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FieldValues>({
    mode: 'onBlur',
    defaultValues: {
      ...estimate,
    },
  });
  const errorsForm: any = errors;
  const form : any = watch();

  const isDeleteEstimate = userAuth?.role ? (userAuth?.role === 'Admin' || userAuth?._id === form.author._id) : false;

  const operationalBasePrice = useMemo(() => {
    const prices : any = {

    };
    const base = operationalBases?.find(
      (o: any) => o.value === form?.logistics?.operationalBase,
    );
    if (base?.hourlyRate) {
      prices.hourlyRate = base?.hourlyRate;
    }
    const transportType = form?.transport?.trucks.type;
    const maxSize = form?.transport?.trucks.maxSize;
    if (base?.trucks && transportType) {
      const transportPrice6m = base?.trucks.find((t: any) => {
        const { key } = t;
        if (key === 'PL-6M' && transportType === 'sans grue') {
          return t;
        }
        if (key === 'PL-6MG' && transportType === 'avec grue') {
          return t;
        }
        return null;
      })?.price;
      if (transportPrice6m) {
        prices.truck6m = transportPrice6m;
      }
      const transportPrice12m = base?.trucks.find((t: any) => {
        const { key } = t;
        if (key === 'PL-13M' && transportType === 'sans grue') {
          return t;
        }
        if (key === 'PL-12-MG' && transportType === 'avec grue') {
          return t;
        }
        return null;
      })?.price;
      if (transportPrice12m && maxSize === '12m') {
        prices.truck12m = transportPrice12m;
      }
    }
    return prices;
  }, [form, operationalBases]);

  const handleDelete = () => {
    if (isPreview) return;
    confirm(
      'Êtes-vous sûr de vouloir supprimer ce devis?',
      async () => {
        await deleteEstimate({
          estimateId: id || '',
        });
        navigate('/estimates');
        const layout = document?.getElementById('layout');
        if (layout) {
          layout.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        }
      },
      () => null,
    );
  };

  const getTransportError = useMemo(() => {
    if (!transportError) return null;
    return transportError;
  }, [transportError]);

  const submit = useCallback(async (data: FieldValues) => {
    await updateEstimate({
      _id: id,
      ...data,
    });
  }, [estimate]);

  const debouncedSubmit = useDebounceCallback(submit, 1500);

  // function to watch change on all field
  const submitOnChange = () => watch(() => {
    handleSubmit(debouncedSubmit)();
  });

  useEffect(() => {
    const subscription = submitOnChange();
    return () => subscription.unsubscribe();
  }, [submitOnChange]);

  return (
    <div className={styles.generalInfos}>
      <section>
        <h4>Information sur le chantier</h4>
        <div className={styles.row}>
          <div className={styles.containerField}>
            {isPreview
              ? <PreviewField
                label='Nom du chantier'
                value={form?.worksite?.name}
              />
              : <InputText
                name='worksite.name'
                label='Nom du chantier'
                control={control}
              />
            }
            {!form?.worksite?.name && <ErrorField message="le nom du chantier est requis" />}
          </div>
          <div className={styles.containerField}>
          {isPreview
            ? <PreviewField
                label='Ville'
                value={form?.worksite?.city}
              />
            : <InputText
              name='worksite.city'
              label='Ville'
              control={control}
            />
          }
          {!form?.worksite?.city && <ErrorField message="la ville est requise" />}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.containerField}>
          {isPreview
            ? <PreviewField
                label='Adresse'
                value={form?.worksite?.address}
              />
            : <InputText
              name='worksite.address'
              label='Adresse'
              control={control}
            />
          }
          </div>
          <div className={styles.containerField}>
          {isPreview
            ? <PreviewField
                label='Date démarrage prévisionnelle'
                value={form?.worksite?.estimatedStartDate ? format(new Date(), 'dd/MM/yyyy') : '---'}
              />
            : <InputText
              name='worksite.estimatedStartDate'
              type='date'
              label='Date démarrage prévisionnelle'
              control={control}
            />
          }
          </div>
        </div>
        <div className={styles.containerField}>
          {isPreview
            ? <PreviewField
                  label="Complément d'adresse"
                  value={form?.worksite?.address2}
                />
            : <InputText
                name='worksite.address2'
                label="Complément d'adresse"
                control={control}
              />
            }
          </div>
          <div className={styles.row}>
          <div className={styles.containerField}>
          {isPreview
            ? <PreviewField
                label='Département'
                value={departments?.find((d: IOption) => d.value === form?.worksite?.department)?.label || '---'}
              />
            : <StyledSelect
              name='worksite.department'
              label='Département'
              isSearchable
              control={control}
              options={departments}
            />
          }
          {!form?.worksite?.department && <ErrorField message="le département est requis" />}
          </div>
          <div className={styles.containerField}>
          {isPreview
            ? <PreviewField
                label='Matériel utilisé'
                value={worksite?.materialsUsed?.find((d: IOption) => d.value === form?.worksite?.materialUsed)?.label || '---'}
              />
            : <StyledSelect
              name='worksite.materialUsed'
              label='Matériel utilisé'
              control={control}
              isClearable
              options={worksite?.materialsUsed || []}
            />
          }
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.containerField}>
          {isPreview
            ? <PreviewField
                label='Charge'
                value={worksite?.loads?.find((d: IOption) => d.value === form?.worksite?.load)?.label || '---'}
              />
            : <StyledSelect
              name='worksite.load'
              label='Charge'
              control={control}
              isClearable
              options={worksite?.loads || []}
            />
          }
          </div>
          <div className={styles.containerField}>
          {isPreview
            ? <PreviewField
                label='Stabilité'
                value={worksite?.stabilities?.find((d: IOption) => d.value === form?.worksite?.stability)?.label || '---'}
              />
            : <StyledSelect
              name='worksite.stability'
              label='Stabilité'
              control={control}
              isClearable
              options={worksite?.stabilities || []}
            />
          }
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.containerField}>
            {isPreview
              ? <PreviewField
                  label='Moyens Accès'
                  value={worksite?.access?.find((d: IOption) => d.value === form?.worksite?.access)?.label || '---'}
                />
              : <StyledSelect
                name='worksite.access'
                label='Moyens Accès'
                control={control}
                isClearable
                options={worksite?.access || []}
              />
            }
          </div>
        </div>
        <div className={styles.containerField}>
          {isPreview
            ? <PreviewField
                label='Description du chantier'
                value={form?.worksite.description || '---'}
            />
            : <Textarea
              name='worksite.description'
              label='Description du chantier'
              control={control}
            />
          }
        </div>
        <div className={styles.containerField}>
          {isPreview
            ? <PreviewField
                label='Informations diverses'
                value={form?.worksite.infos || '---'}
            />
            : <Textarea
              name='worksite.infos'
              label='Informations diverses'
              control={control}
            />
          }
        </div>
      </section>
      <hr />
      <section>
        <h4>Contact côté client</h4>
        <div className={styles.row}>
          <div className={styles.containerField}>
          {isPreview
            ? <PreviewField
                label='Nom'
                value={form?.contact?.lastName}
              />
            : <InputText
              name='contact.lastName'
              label='Nom'
              rules={requiredRule}
              control={control}
            />
          }
           {errorsForm?.contact?.lastName?.message
            && <ErrorField message={errorsForm?.contact?.lastName.message} />}
          </div>
          <div className={styles.containerField}>
          {isPreview
            ? <PreviewField
                label='Prénom'
                value={form?.contact?.firstName}
              />
            : <InputText
              name='contact.firstName'
              label='Prénom'
              rules={requiredRule}
              control={control}
            />
          }
          {errorsForm?.contact?.firstName?.message
            && <ErrorField message={errorsForm?.contact?.firstName?.message} />}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.containerField}>
          {isPreview
            ? <PreviewField
                label="Téléphone"
                value={form?.contact?.phone}
              />
            : <InputFormatPattern
              name="contact.phone"
              label="Téléphone"
              control={control}
              format="## ## ## ## ##"
              rules={{
                pattern: {
                  value: /^(?:\s*\d){10}$/,
                  message: "Le champs de numéro de téléphone n'est pas valide",
                },
              }}
            />
            }
          </div>
          <div className={styles.containerField}>
          {isPreview
            ? <PreviewField
                label="Adresse mail"
                value={form?.contact?.email}
              />
            : <InputEmail
              name="contact.email"
              control={control}
              label="Adresse mail"
              placeholder='adressemail@mail.fr'
              required
            />
            }
            {errorsForm?.contact?.email?.message
            && <ErrorField message={errorsForm?.contact?.email.message} />}
          </div>
        </div>
      </section>
      <hr />
      <section>
        <h4>Main d’oeuvre</h4>
        <div className={styles.row}>
          <div className={styles.containerField}>
            {isPreview
              ? <PreviewField
                  label='Destination'
                  value={operationalBases?.find((d: IOption) => d.value === form?.logistics?.operationalBase)?.label || '---'}
                />
              : <>
            <StyledSelect
              name='logistics.operationalBase'
              label='Destination'
              control={control}
              isSearchable
              options={operationalBases}
            />
            {form?.logistics?.operationalBase && !operationalBasePrice?.hourlyRate && <ErrorField message="la destination est incompléte" />}
            {!form?.logistics?.operationalBase && <ErrorField message="la destination est requise" />}
            </>
          }
          </div>
          {operationalBasePrice?.hourlyRate
          && (estimate?.hourlyRate && !estimate?.hourlyRate?.price)
            && <p className={`${styles.rate} ${form.logistics.discountMO > 0 ? styles.discount : ''}`}>
              Taux horaire : <span>{operationalBasePrice?.hourlyRate} €</span>{' '}{form.logistics.discountMO > 0 && <span>{(operationalBasePrice.hourlyRate * (1 - form.logistics.discountMO)).toFixed(2)} €</span>}<br/>
            </p>
          }
          {estimate?.hourlyRate && estimate?.hourlyRate?.price
            && <p className={`${styles.rate} ${form.logistics.discountMO > 0 ? styles.discount : ''}`}>
              Taux horaire : <span>{estimate?.hourlyRate?.price.toFixed(2)} €</span>{' '}{form.logistics.discountMO > 0 && <span>{(estimate.hourlyRate.price * (1 - form.logistics.discountMO)).toFixed(2)} €</span>}<br/>
            </p>
          }
        </div>
        {estimate?.worksite?.city && estimate?.agency && id
        && <div className={styles.transport}>
            <div className={styles['transport-details']}>
            <div className={styles.col}>
              <p>{estimate?.agency.name} - {estimate?.worksite?.city}</p>
            </div>
            <div className={styles.col}>
              <p>{estimate?.hourlyRate?.km?.toFixed() || '---'} km</p>
            </div>
            <div className={styles.col}>
              <p>{estimate?.hourlyRate?.minutes ? formatDuration(estimate?.hourlyRate.minutes) : '---'}</p>
            </div>
            <div className={styles.col}>
              <p>{estimate?.hourlyRate?.price?.toFixed(2) || '---'} €</p>
            </div>
            <button
              onClick={async () => {
                await updateEstimateTransport({ estimateId: id }, {
                  onSuccess: (response) => {
                    if (response?.estimate) {
                      reset(response.estimate);
                    }
                  },
                });
              }}
            >
              <GrUpdate />
            </button>
          </div>
          {getTransportError && <ErrorField message={"Une des villes n'as pas été trouvée"} />}
        </div>
        }
        <div className={styles.row}>
          <div className={`${styles.containerField} ${!isPreview ? styles.discount : ''}`}>
          {isPreview
            ? <PreviewField
                label='Remise sur la MO'
                className='discount'
                value={form?.logistics?.discountMO ? `${form.logistics.discountMO * 100} %` : '---'}
            />
            : <StyledSelect
              name='logistics.discountMO'
              label='Remise sur la MO'
              control={control}
              className={'discount'}
              options={discount?.workforce}
            />
          }
          </div>
        </div>
      </section>
      <hr />
      <section>
        <h4>Location</h4>
        <div className={styles.row}>
          <div className={`${styles.containerField} ${!isPreview ? styles.discount : ''}`}>
          {isPreview
            ? <PreviewField
                label='Remise sur la location'
                className='discount'
                value={form?.location?.discount ? `${form.location.discount * 100} %` : '---'}
            />
            : <StyledSelect
              name='location.discount'
              label='Remise sur la location'
              control={control}
              className={'discount'}
              options={discount?.location}
            />
            }
          </div>
          <div className={`${styles.containerField} ${styles.duration}`}>
          {isPreview
            ? <PreviewField
                label='Durée global du chantier'
                value={form?.worksite?.duration ? `${form?.worksite?.duration} mois` : '---'}
            />
            : <>
              <label>Durée global du chantier</label>
              <div className={styles.fields}>
                <InputNumber
                    name='worksite.duration'
                    control={control}
                  />
              </div>
              </>
            }
          </div>
        </div>
        <div className={`${styles.containerField} ${styles.checkbox}`}>
          <Checkbox
            name='location.globalPrice'
            label='Appliquer un tarif global'
            control={control}
            disabled={isPreview}
          />
        </div>
      </section>
      <hr />
      <section>
        <h4>Transport</h4>
        <div className={styles.row}>
          <div className={styles.containerField}>
          {isPreview
            ? <PreviewField
                label='Taille maximale des camions'
                value={worksite?.trucksMaxSizes?.find((d: IOption) => d.value === form?.transport?.trucks?.maxSize)?.label || '---'}
              />
            : <StyledSelect
              name='transport.trucks.maxSize'
              label='Taille maximale des camions'
              placeholder='Taille maximale des camions'
              control={control}
              options={worksite?.trucksMaxSizes || []}
            />
          }
          </div>
          <div className={styles.containerField}>
          {isPreview
            ? <PreviewField
                label='Type de camion'
                value={worksite?.trucksTypes?.find((d: IOption) => d.value === form?.transport?.trucks?.type)?.label || '---'}
              />
            : <StyledSelect
              name='transport.trucks.type'
              label='Type de camion'
              placeholder='Type de camion'
              control={control}
              options={worksite?.trucksTypes || []}
            />
          }
          </div>
        </div>
        <div className={styles.row}>
          <div className={`${styles.containerField} ${!isPreview ? styles.discount : ''}`}>
            {isPreview
              ? <PreviewField
                  label='Remise sur les transports'
                  className='discount'
                  value={form?.transport?.discount ? `${form.transport.discount * 100} %` : '---'}
              />
              : <StyledSelect
                name='transport.discount'
                label='Remise sur les transports'
                control={control}
                className={'discount'}
                options={discount?.transport}
              />
            }
          </div>
        </div>
        {operationalBasePrice?.truck6m
          && <p className={`${styles.rate} ${form.transport.discount > 0 ? styles.discount : ''}`}>
            Taux transport 6m : <span>{operationalBasePrice.truck6m} €</span>{' '}{form?.transport?.discount > 0 && <span>{(operationalBasePrice.truck6m * (1 - form.transport.discount)).toFixed(2)} €</span>}
          </p>
        }
        {operationalBasePrice?.truck12m
          && <p className={`${styles.rate} ${form.transport.discount > 0 ? styles.discount : ''}`}>
            Taux transport 12m : <span>{operationalBasePrice.truck12m} €</span>{' '}{form?.transport?.discount > 0 && <span>{(operationalBasePrice.truck12m * (1 - form.transport.discount)).toFixed(2)} €</span>}
          </p>
        }
      </section>
      {!isPreview && !form.sendAt && isDeleteEstimate
          && <button
            type="button"
            className={styles.delete}
            onClick={() => handleDelete()}
          >
            <IoTrash /> Supprimer le devis
          </button>
        }
    </div>
  );
};

export default withModalConfirm(GeneralInfos);
