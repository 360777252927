import React from 'react';
import { useFormContext } from 'react-hook-form';
import styles from './elevator-form.module.scss';

import {
  InputNumber,
  StyledSelect,
} from '~/lib/HooksFormFields';
import PreviewField from '~/components/PreviewField';
import { IOption } from '~/types/options';
import { useGetEstimatesList } from '~/hooks/estimates';

const ElevatorForm = ({ isPreview } : { isPreview: boolean }) => {
  const { control, watch, setValue } = useFormContext();
  const phase = watch();
  const { data: list = { elevators: [] } } = useGetEstimatesList();
  const isElevator = phase.type === 'elevator' || phase.elevator.type !== '';

  return (
    <div id="elevator" className={styles.elevator}>
      <h4>Ascenseur</h4>
      {phase.type !== 'elevator'
        && <div className={styles.row}>
          <div className={`${styles.containerField} ${styles.checkbox}`}>
          {isPreview
            ? <PreviewField
                label="Choix de l'ascenseur"
                value={list.elevators?.find((a:IOption) => a.value === phase.elevator.type)?.label || '---'}
              />
            : <StyledSelect
              label="Choix de l'ascenseur"
              name='elevator.type'
              control={control}
              options={list.elevators}
              handleChange={(opt: IOption) => {
                if (opt.value === '') {
                  setValue('elevator', {
                    type: '',
                    transport: {
                      go: null,
                      back: null,
                    },
                    assembly: null,
                    disassembly: null,
                    rental: null,
                    duration: null,
                    dissassmblyDuration: null,
                  });
                }
              }}
            />
          }
          </div>
        </div>
      }
      {isElevator
      && <>
      <h5>Transport</h5>
      <div className={`${styles.row}`}>
        <div className={`${styles.containerField}`}>
        {isPreview
          ? <PreviewField
                  label='Transport aller'
                value={phase?.elevator?.transport?.go ? `${phase?.elevator?.transport?.go} €` : '---'}
            />
          : <InputNumber
            name='elevator.transport.go'
            label='Transport aller'
            control={control}
          />
        }
        </div>
        <div className={`${styles.containerField}`}>
        {isPreview
          ? <PreviewField
              label='Transport retour'
              value={phase?.elevator?.transport?.back ? `${phase?.elevator?.transport?.back} €` : '---'}
            />
          : <InputNumber
            name='elevator.transport.back'
            label='Transport retour'
            control={control}
          />
        }
        </div>
      </div>
      <h5>Montage/Démontage</h5>
      <div className={`${styles.row}`}>
        <div className={`${styles.containerField}`}>
        {isPreview
          ? <PreviewField
              label='Montage'
              value={phase?.elevator?.assembly ? `${phase?.elevator?.assembly} €` : '---'}
            />
          : <InputNumber
            name='elevator.assembly'
            label='Montage'
            control={control}
          />
        }
        </div>
        <div className={`${styles.containerField}`}>
          {isPreview
            ? <PreviewField
                label='Montage'
                value={phase?.elevator?.disassembly ? `${phase?.elevator?.disassembly} €` : '---'}
              />
            : <InputNumber
              name='elevator.disassembly'
              label='Démontage'
              control={control}
            />
          }
        </div>
      </div>
      <h5>Location</h5>
      <div className={`${styles.row}`}>
        <div className={`${styles.containerField}`}>
        {isPreview
          ? <PreviewField
                label='Prix de la location'
                value={phase?.elevator?.rental ? `${phase?.elevator?.rental} €` : '---'}
              />
          : <InputNumber
            name='elevator.rental'
            label='Prix de la location'
            control={control}
          />
        }
        </div>
      </div>
      <h5>Durée de location</h5>
      <div className={`${styles.row}`}>
        <div className={`${styles.containerField}`}>
        {isPreview
          ? <PreviewField
              label='Durée de location au montage'
              value={phase?.elevator?.duration ? `${phase?.elevator?.duration} €` : '---'}
            />
          : <InputNumber
            name='elevator.duration'
            label='Durée de location au montage'
            control={control}
          />
        }
        </div>
        {phase?.elevator?.type === 'assembly-disassembly'
          && <div className={`${styles.containerField}`}>
          {isPreview
            ? <PreviewField
                label='Durée de location au montage'
                value={phase?.elevator?.disassemblyDuration ? `${phase?.elevator?.disassemblyDuration} €` : '---'}
              />
            : <InputNumber
              name='elevator.disassemblyDuration'
              label='Durée de location au démontage'
              control={control}
            />
          }
          </div>
        }
      </div>
      </>
      }
    </div>
  );
};

export default ElevatorForm;
