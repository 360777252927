import React from 'react';
import { useFormContext } from 'react-hook-form';
import styles from './handling-form.module.scss';

import {
  InputNumber,
  StyledSelect,
} from '~/lib/HooksFormFields';
import { useGetEstimatesList } from '~/hooks/estimates';
import PreviewField from '~/components/PreviewField';
import { IOption } from '~/types/options';

const HandlingForm = ({ isPreview } : { isPreview: boolean }) => {
  const { control, watch } = useFormContext();
  const { data: list = {} } = useGetEstimatesList();

  const phase = watch();
  console.log(phase.handling.assembly?.type);
  return (
    <div id="manutention" className={styles.handling}>
      <h4>Manutention</h4>
      <div className={styles.row}>
        <div className={styles.col}>
          <div className={`${styles.containerField}`}>
          {isPreview
            ? <PreviewField
                label='Type de manutention'
                value={list?.handlingTypes?.find((a:IOption) => a.value === phase?.handling?.assembly?.type)?.label || '---'}
              />
            : <StyledSelect
              label='Manutention montage'
              name='handling.assembly.type'
              control={control}
              options={list?.handlingTypes}
            />
            }
          </div>
          <div className={`${styles.containerField}`}>
            {isPreview
              ? <PreviewField
                label='% de manutention montage'
                value={phase?.handling?.assembly?.percentage ? `${phase?.handling?.assembly?.percentage} %` : '---'}
              />
              : <InputNumber
                name='handling.assembly.percentage'
                label='% de manutention'
                max={100}
                control={control}
              />
            }
            </div>
        </div>
        <div className={styles.col}>
          <div className={`${styles.containerField}`}>
          {isPreview
            ? <PreviewField
                label='Manutention démontage'
                value={list?.handlingTypes?.find((a:IOption) => a.value === phase?.handling?.disassembly?.type)?.label || '---'}
              />
            : <StyledSelect
              label='Manutention démontage'
              name='handling.disassembly.type'
              control={control}
              options={list?.handlingTypes}
            />
            }
          </div>
          <div className={`${styles.containerField}`}>
            {isPreview
              ? <PreviewField
                label='% de manutention démontage'
                value={phase?.handling?.disassembly?.percentage ? `${phase?.handling?.disassembly?.percentage} %` : '---'}
              />
              : <InputNumber
                name='handling.disassembly.percentage'
                label='% de manutention démontage'
                max={100}
                control={control}
              />
            }
            </div>
        </div>
      </div>
    </div>
  );
};

export default HandlingForm;
